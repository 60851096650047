#headerTitle {
  /* margin-bottom: 50px; */
  margin-top: 10px;
}

h2 a {
  color: aquamarine;
  font-size: 32px;
  letter-spacing: 5px;
}

h2 a:hover {
  text-decoration: none;
  color: rgb(3, 155, 84);
}

#headerTag {
  color: aquamarine;
  font-size: 16px;
  letter-spacing: 2px;
}

.header-area {
  margin-top: 30px;
}
